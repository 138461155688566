import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../App.css';
import Master from '../assets/money.png';
import Visa from '../assets/visa.png';
import Apple from '../assets/apple-pay.png';
import Google from '../assets/google-pay.png';
import Amex from '../assets/amex.png';

// Footer component
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer
      style={{
        backgroundColor: '#282828',
        color: '#ffffff',
        padding: '10px 20px',
        borderRadius: '15px',
        margin: '20px 0',
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* Address and Phone */}
          <div className="col-md-4" style={{ textAlign: 'center' }}>
            <h5 style={{ color: '#ff6600', marginBottom: '10px' }}>{t('adAndCellPhone')}:</h5>
            <p style={{ color: 'black', marginBottom: '5px' }}>4321 Av. Papineau, Montréal, QC H2H 1T3</p>
            <a
              href="https://api.whatsapp.com/send?phone=14386808564"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-success"
              style={{
                padding: '5px 15px',
                borderRadius: '20px',
                fontSize: '0.9rem',
              }}
            >
              438 680 8564
            </a>
          </div>

          {/* Keep in Touch */}
          <div className="col-md-4" style={{ textAlign: 'center' }}>
            <h5 style={{ color: '#ff6600', marginBottom: '10px' }}>{t('KeepInTouch')}:</h5>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
              <Link
                to="/search"
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                }}
              >
                {t('Search')}
              </Link>
              <Link
                to="/privacy-policy"
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                }}
              >
                {t('PrivPolicy')}
              </Link>
              <Link
                to="/return-policy"
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                }}
              >
                {t('RetPolicy')}
              </Link>
              <Link
                to="/faq"
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                }}
              >
                FAQs
              </Link>
              <Link
                to="/services"
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                }}
              >
                {t('TermService')}
              </Link>
            </div>
          </div>

          {/* Payment Methods */}
          <div className="col-md-4" style={{ textAlign: 'center' }}>
            <h5 style={{ color: '#ff6600', marginBottom: '10px' }}>{t('PayMeth')}:</h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <img src={Visa} alt="Visa" style={{ height: '24px' }} />
              <img src={Master} alt="Mastercard" style={{ height: '24px' }} />
              <img src={Apple} alt="Apple Pay" style={{ height: '24px' }} />
              <img src={Google} alt="Google Pay" style={{ height: '24px' }} />
              <img src={Amex} alt="American Express" style={{ height: '24px' }} />
            </div>
          </div>
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            marginTop: '10px',
            paddingTop: '10px',
            textAlign: 'center',
          }}
        >
          <p style={{ margin: 0, fontSize: '0.8rem', color: '#bbbbbb' }}>
            © {new Date().getFullYear()}, Onord Laptop. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;