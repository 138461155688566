import React from "react";
import { useTranslation } from "react-i18next";

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <div className="container mt-5">
      <h2 className="text-center">{t("tos_title")}</h2>
      <p>{t("tos_overview")}</p>

      <h3>{t("tos_section_1_title")}</h3>
      <p>{t("tos_section_1_content")}</p>

      <h3>{t("tos_section_2_title")}</h3>
      <p>{t("tos_section_2_content")}</p>

      <h3>{t("tos_section_3_title")}</h3>
      <p>{t("tos_section_3_content")}</p>

      <h3>{t("tos_section_4_title")}</h3>
      <p>{t("tos_section_4_content")}</p>

      <h3>{t("tos_section_5_title")}</h3>
      <p>{t("tos_section_5_content")}</p>

      <h3>{t("tos_section_6_title")}</h3>
      <p>{t("tos_section_6_content")}</p>

      <h3>{t("tos_section_7_title")}</h3>
      <p>{t("tos_section_7_content")}</p>

      <h3>{t("tos_section_8_title")}</h3>
      <p>{t("tos_section_8_content")}</p>

      <h3>{t("tos_section_9_title")}</h3>
      <p>{t("tos_section_9_content")}</p>

      <h3>{t("tos_section_10_title")}</h3>
      <p>{t("tos_section_10_content")}</p>

      <h3>{t("tos_section_11_title")}</h3>
      <p>{t("tos_section_11_content")}</p>

      <h3>{t("tos_section_12_title")}</h3>
      <p>{t("tos_section_12_content")}</p>

      <h3>{t("tos_section_13_title")}</h3>
      <p>{t("tos_section_13_content")}</p>

      <h3>{t("tos_section_14_title")}</h3>
      <p>{t("tos_section_14_content")}</p>

      <h3>{t("tos_section_15_title")}</h3>
      <p>{t("tos_section_15_content")}</p>

      <h3>{t("tos_section_16_title")}</h3>
      <p>{t("tos_section_16_content")}</p>

      <h3>{t("tos_section_17_title")}</h3>
      <p>{t("tos_section_17_content")}</p>

      <h3>{t("tos_section_18_title")}</h3>
      <p>{t("tos_section_18_content")}</p>

      <h3>{t("tos_section_19_title")}</h3>
      <p>{t("tos_section_19_content")}</p>

      <h3>{t("tos_section_20_title")}</h3>
      <p>{t("tos_section_20_content")}</p>
    </div>
  );
};

export default TermsOfService;
