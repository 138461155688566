import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// About page component
const About = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  // function to handle the form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // function to handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone, message } = formData;

    const mailtoLink = `mailto:onordlaptop@gmail.com?subject=Application for Internship&body=Name: ${name}%0D%0AEmail: ${email}%0D%0APhone: ${phone}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  // content of the about page
  return (
    <div className="container mt-5">
      <h1 className="text-center">{t("about")}</h1>
      <div className="text">
        <p>{t('paragraph1')}</p>
        <p>{t('paragraph2')}</p>
        <p>{t('paragraph3')}</p>
        <p>{t('paragraph4')}</p>
        <p className="fw-bold">{t('paragraph5')}</p>
        <p>
          {t('paragraph6')}
          <a href="mailto:onordlaptop@gmail.com">onordlaptop@gmail.com</a>
          <br />
          {t('paragraph62')}
        </p>
        <p>{t('paragraph7')}</p>
      </div>
      <button
        className="btn btn-primary mt-4"
        onClick={() => setShowForm(!showForm)}
      >{t('careers')}</button>
      {/* Form for internship application */}
      {showForm && (
        <div className="mt-4">
          <h3>{t('internship')}</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">{t('name')}</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">{t('email')}</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">{t('phone')}</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">{t('message')}</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-success">{t('submit')}</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default About;
