import React, { useState } from 'react';
import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import franceFlag from '../assets/france.png';
import spainFlag from '../assets/spain.png';
import unitedKignomFlag from '../assets/gb.png';
import Logo from '../assets/laptop.png';

// Header component
const Header = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  // Function to change the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // Function to handle the search
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== "") {
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };
  // Content of the header
  return (
    <header className="header-container">
      <Navbar bg="light" expand="lg" className="p-3 d-flex justify-content-between align-items-center">
        <Navbar.Brand as={Link} to="/" className="logo-container">
          <img
            src={Logo}
            alt="Logo"
            style={{ height: '50px' }}
          />
        </Navbar.Brand>
        <div className="welcome-text">
          <h1 className="fs-4">{t('welcome')}</h1>
        </div>
        <div className="language-select d-flex align-items-center">
          <img
            src={unitedKignomFlag}
            alt="EN-CA"
            onClick={() => changeLanguage('en')}
            className="flag-icon"
            style={{ cursor: 'pointer' }}
          />
          <img
            src={franceFlag}
            alt="FR-CA"
            onClick={() => changeLanguage('fr')}
            className="flag-icon"
            style={{ cursor: 'pointer' }}
          />
          <img
            src={spainFlag}
            alt="ES"
            onClick={() => changeLanguage('es')}
            className="flag-icon"
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="icons-container d-flex justify-content-end">
          <Nav.Link as={Link} to="/login" className="text-dark" aria-label="Login">
            <FaUser />
          </Nav.Link>
        </div>
      </Navbar>
      <nav className="nav-links-container mt-2">
        <Nav className="justify-content-center">
          <div className="justify-content-center align-items-center d-flex me-3"
            style={{ fontSize: '20px', color: 'white' }}>
            <Nav.Link as={Link} to="/" className="text-white">{t('home')}</Nav.Link>
            <Nav.Link as={Link} to="/about" className="text-white">{t('about')}</Nav.Link>
            <Nav.Link as={Link} to="/products" className="text-white">{t('products')}</Nav.Link>
            <Nav.Link as={Link} to="/contact" className="text-white">{t('contact')}</Nav.Link>
          </div>
          <Form className="d-flex align-items-center" onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder={t('Search')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="me-2"
              style={{ width: '200px', height: '35px' }}
            />
            <Button
              variant="light"
              type="submit"
              style={{
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-9px'
              }}
            >
              <FaSearch />
            </Button>
          </Form>
        </Nav>
      </nav>
    </header>
  );
};

export default Header;