import React, {useState, useEffect, useRef} from "react";
import {collection, onSnapshot, addDoc, updateDoc, deleteDoc, doc} from "firebase/firestore";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import {useNavigate} from "react-router-dom";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {db, auth} from "./firebase";
import {useTranslation} from "react-i18next";
import i18n from "../i18n";
import { deleteObject } from "firebase/storage";
import "../App.css";

//constants for the admin page
const Admin = () => {
  const [products, setProducts] = useState([]); // List of products
  const [name, setName] = useState(""); // Product name
  const [code, setCode] = useState(""); // Product code
  const [price, setPrice] = useState(""); // Product price
  const [description, setDescription] = useState({ en: "", fr: "", es: "" }); // Product description
  const [images, setImages] = useState([]); // Product images
  const [editingProductId, setEditingProductId] = useState(null); // ID of product being edited
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const storage = getStorage();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const fileInputRef = useRef();
  const videoRefs = useRef([]);


  // Check user authentication and fetch products
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      }
    });
    // Fetch products
    const unsubscribeSnapshot = onSnapshot(collection(db, "products"), (snapshot) => {
      const productList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productList);
    });

    return () => {
      unsubscribeAuth();
      unsubscribeSnapshot();
    };
  }, [navigate]);

  // Function to generate keywords from a string
  const generateKeywords = (name, description) => {
    const nameKeywords = name
      .toLowerCase()
      .split(" ")
      .map((word) => word.trim());
    const descriptionKeywords = Object.values(description)
      .join(" ")
      .toLowerCase()
      .split(" ")
      .map((word) => word.trim());
    const keywords = [...new Set([...nameKeywords, ...descriptionKeywords])]; // Remove duplicates
    return keywords;
  };

  // Function to add a new product
  const handleAddProduct = async (e) => {
    e.preventDefault();
    if (editingProductId) {
      handleUpdateProduct();
      return;
    }

    setIsLoading(true);
    try {
      const uploadedFiles = await handleFileUpload(images);
      const keywords = generateKeywords(name, description);
      await addDoc(collection(db, "products"), {
        name,
        code,
        price: parseFloat(price),
        description,
        media: uploadedFiles, // Image URLs
        keywords,
      });
      alert("¡Producto agregado con éxito!");
      resetForm();
    } catch (error) {
      alert("Error al agregar producto: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to edit an existing product
  const handleEditProduct = (product) => {
    setEditingProductId(product.id);
    setName(product.name);
    setPrice(product.price);
    setDescription(product.description);
    setImages([]); // Clear images for new selection
  };

  // Function to update an existing product
  const handleUpdateProduct = async () => {
    setIsLoading(true);
    try {
      let uploadedFiles = [];
      if (images.length > 0) {
        uploadedFiles = await handleFileUpload(images);
      }
      const keywords = generateKeywords(name, description);
      const productRef = doc(db, "products", editingProductId);
      await updateDoc(productRef, {
        name,
        code,
        price: parseFloat(price),
        description,
        ...(uploadedFiles.length > 0 && { media: uploadedFiles }), // Update files if new ones are uploaded
        keywords,
      });
      alert("¡Producto actualizado con éxito!");
      resetForm();
    } catch (error) {
      alert("Error al actualizar producto: " + error.message);
    } finally {
      setIsLoading(false);
      setEditingProductId(null);
    }
  };

  // Function to delete a product
  const handleDeleteProduct = async (productId) => {
    try {
      const productToDelete = products.find((product) => product.id === productId);
      if (productToDelete && productToDelete.media) {
        const deleteMediaPromises = productToDelete.media.map(async (file) => {
          try{
            const fileRef = ref(storage, decodeURIComponent(file.url.split("/o/")[1].split("?")[0]));
            await deleteObject(fileRef); // attempt to delete the file
          } catch (error) {
            console.warn(`Error to delete image: ${file.url}`, error); // just log the error and continue deleting the other images
          }
        });
        await Promise.all(deleteMediaPromises);
      }
      await deleteDoc(doc(db, "products", productId));
      alert("¡Producto eliminado con éxito!");
    } catch (error) {
      alert("Error al eliminar producto: " + error.message);
    }
  };

  // Function to reset the form
  const resetForm = () => {
    setName("");
    setCode("");
    setPrice("");
    setDescription({ en: "", fr: "", es: "" });
    setImages([]);
    setEditingProductId(null);
  };

  // Function to close the modal
  const closeModal = () => {
    if (selectedMedia?.type === "video") {
      const modalVideo = videoRefs.current.find((ref) => ref?.src === selectedMedia.url);
      if (modalVideo) {
        modalVideo.pause();
      }
    }

    setSelectedMedia(null);
    setShowModal(false);
  };

  // Images functions group
  // Function to upload media
  const handleFileUpload = async (files) => {
    const uploadedFiles = [];
    for (let file of files) {
      if (!file || !file.name) {
        console.error('Invalid File:', file);
        continue;
      }
      try {
        const storageRef = ref(storage, `products/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        uploadedFiles.push({ url, type: file.type.startsWith("video/") ? "video" : "image" });
      } catch (error) {
        console.error(`Error al cargar el archivo ${file.name}:`, error);
      }
    }
    return uploadedFiles;
  };

  // Function to render media
  const renderMedia = (media) => {
    if (media && media.length > 0) {
      return media.map((file, index) => {
        if (file.type === "video") {
          return (
            <video
              ref={(el) => (videoRefs.current[index] = el)}
              key={index}
              controls
              muted
              src={file.url}
              className="img-thumbnail m-2"
              style={{ maxWidth: "100px", height: "auto", cursor: "pointer" }}
              onClick={() => handleMediaClick(file, index)}
            />
          );
        }
        return (
          <img
            key={index}
            src={file.url}
            alt={`Media ${index + 1}`}
            className="img-thumbnail m-2"
            style={{ maxWidth: "100px", height: "auto", cursor: "pointer" }}
            onClick={() => handleMediaClick(file, index)}
          />
        );
      });
    }
    return <p>{t("no Images Available")}</p>;
  };

  // Function to render image preview
  const renderMediaPreview = () => {
    return images.map((file, index) => {
      if (file.type && file.type.startsWith("video/")) {
        return (
          <div key={index} className="d-inline-block position-relative m-2">
            <video
              src={URL.createObjectURL(file)}
              controls
              className="img-thumbnail"
              style={{ maxWidth: "100px", height: "auto" }}
            ></video>
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0"
              aria-label="Excluir"
              onClick={() => handleDeleteImage(index)}
            ></button>
          </div>
        );
      }
      return (
        <div key={index} className="d-inline-block position-relative m-2">
          <img
            src={URL.createObjectURL(file)}
            alt={`Previsualización ${index + 1}`}
            className="img-thumbnail"
            style={{ maxWidth: "100px", height: "auto" }}
          />
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0"
            aria-label="Excluir"
            onClick={() => handleDeleteImage(index)}
          ></button>
        </div>
      );
    });
  };

  // Function to handle image selection
  const handleAddImages = (event) => {
    const newFiles = Array.from(event.target.files);
    setImages((prev) => {
      const existingFiles = new Set(prev.map((file) => file.name)); // Verify if the file already exists
      return [...prev, ...newFiles.filter((file) => !existingFiles.has(file.name))];
    });
  };

  // Function to handle image click
  const handleMediaClick = (media, index) => {
    videoRefs.current.forEach((video, i) => {
      if (video && !video.paused && i !== index) {
        video.pause();
      }
    });

    setSelectedMedia(media);
    setShowModal(true);
  };

  // Function to delete an image
  const handleDeleteImage = (index) => {
    setImages((prev) => prev.filter((_, i) => i !== index)); // Removes the image at the given index
  };

  // Logout function
  const handleLogout = () => {
    signOut(auth);
    navigate("/login");
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Administración de Productos</h2>
      <form onSubmit={handleAddProduct}>
        <div className="mb-3">
          <label className="form-label">Nombre:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">código:</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Precio:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            className="form-control"
            step="0.01"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Descripción (EN):</label>
          <textarea
            value={description.en}
            onChange={(e) => setDescription({ ...description, en: e.target.value })}
            required
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Descripción (FR):</label>
          <textarea
            value={description.fr}
            onChange={(e) => setDescription({ ...description, fr: e.target.value })}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Descripción (ES):</label>
          <textarea
            value={description.es}
            onChange={(e) => setDescription({ ...description, es: e.target.value })}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Imágenes:</label>
          <button
            type="button"
            className="btn btn-secondary w-100 mb-3"
            onClick={() => fileInputRef.current.click()}
          >
            Agregar Média
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleAddImages}
            multiple
            accept="image/*,video/*"
            style={{ display: "none" }}
          />
          <div className="d-flex flex-wrap">{renderMediaPreview()}</div>
        </div>
        <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
          {isLoading ? "Cargando..." : editingProductId ? "Actualizar Producto" : "Agregar Producto"}
        </button>
      </form>

      {/* Lista de productos */}
      <h3 className="mt-5">Lista de Productos</h3>
            <ul className="list-group">
        {products.map((product) => (
          <li
            key={product.id}
            className="list-group-item d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center"
            style={{ overflow: "hidden" }}
          >
            <div style={{ flex: 1, marginRight: "1rem" }}>
              <h5 className="mb-1">{product.name}</h5>
              <p className="mb-1">Precio: ${product.price.toFixed(2)}</p>
              <p className="mb-2" style={{ whiteSpace: "normal" }}>
                Descripción: {product.description[currentLanguage] || "Descripción no disponible"}
              </p>
              <div
                className="d-flex flex-nowrap overflow-auto"
                style={{
                  maxWidth: "100%",
                  gap: "10px",
                  padding: "5px 0",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {renderMedia(product.media)}
              </div>
            </div>
            <div className="d-flex flex-column mt-3 mt-md-0 align-items-md-center">
              <button
                onClick={() => handleEditProduct(product)}
                className="btn btn-warning btn-sm mb-2"
                style={{ marginBottom: "10px" }}
              >
                Editar
              </button>
              <button
                onClick={() => handleDeleteProduct(product.id)}
                className="btn btn-danger btn-sm"
              >
                Eliminar
              </button>
            </div>
          </li>
        ))}
      </ul>
    
      {showModal && selectedMedia && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          onClick={closeModal}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body text-center">
                {selectedMedia.type === "video" ? (
                  <video
                    controls
                    autoPlay
                    muted
                    src={selectedMedia.url}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <img
                    src={selectedMedia.url}
                    alt="Full screen media"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <button onClick={handleLogout} className="btn btn-secondary mt-4 w-100">
        Cerrar Sesión
      </button>
    </div>
  );
};

export default Admin;
