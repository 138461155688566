import React from 'react';
import { useTranslation } from 'react-i18next';

// Contact page component
const Contact = () => {
  const { t } = useTranslation();
  // content of the contact page
  return (
    <div className="container my-5">
      <h2>{t('find')}</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.112264285367!2d-73.5705216844439!3d45.537487679101824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91bc98ef01217%3A0xbaa5b1f5c3644f8d!2s4321%20Av.%20Papineau%2C%20Montr%C3%A9al%2C%20QC%20H2H%201T3%2C%20Canada!5e0!3m2!1sen!2sca!4v1698287266117!5m2!1sen!2sca"
        width="100%"
        height="450"
        allowFullScreen=""
        loading="lazy"
        title="Location"
      ></iframe>
      <a href="https://api.whatsapp.com/send?phone=14386808564" target="_blank" rel="noopener noreferrer" className="btn btn-success mt-4">
        {t('Chat')}
      </a>
    </div>
  );
};

export default Contact;
