import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fr: {
    translation: {
        welcome: 'Bienvenue chez Onord Laptop Canada!',
        /* Header bar */
        home: 'Accueil',
        products: 'Produits',
        about: 'À propos de nous',
        contact: 'Contact',
        Search: 'Rechercher',
        searchTitle: 'Rechercher des produits',
        searchButton: 'Rechercher',
        searchPlaceholder: 'Rechercher des produits...',
        /* About us page */
        paragraph1: 'Onord Laptop sert des clients du monde entier. Nous nous efforçons de garder les clients heureux avec des prix avantageux et un excellent service client! Transportant toutes les dernières marques, Onord Laptop est un guichet unique pour tous vos besoins quotidiens! Service super rapide garanti que toutes les commandes sont expédiées dans les 48 heures et des informations de suivi sont fournies pour chaque commande! N\'hésitez pas à nous contacter pour toute question ou préoccupation que vous pourriez avoir.',
        paragraph2: 'Notre objectif est d\'importer des produits de qualité et de fournir une grande valeur et une expérience de magasinage à nos clients. Nous croyons que vous serez également satisfait à 100% de nos produits et services. Comme toujours, nous sommes très fiers d\'offrir les meilleurs prix absolus et de ne pas compromettre la qualité.',
        paragraph3: 'Nous essayons toujours de fournir aux clients une excellente expérience de magasinage en ligne en garantissant non seulement un processus d\'achat fluide, mais également un bon service après-vente et des ordinateurs portables d\'occasion à vendre. N\'hésitez pas à nous contacter et à nous donner des commentaires, des retours, des suggestions ou même des critiques.',
        paragraph4: 'La vie est trop courte pour vivre dans le chaos, prenez-la facilement et amusez-vous en utilisant des trucs créatifs.',
        paragraph5: 'Merci de votre visite - n\'hésitez pas à nous contacter si vous avez des questions.',
        paragraph6: 'Email: ',
        paragraph62: 'Heures d\'ouverture: de 9 h à 18 h (du lundi au vendredi)',
        paragraph7: 'Bonne magasinage!',
        careers: 'Carrières',
        internship: 'Postuler pour un stage chez Onord',
        name: 'Nom',
        email: 'courriel',
        phone: 'Téléphone',
        message: 'Message',
        submit: 'Soumettre',
        /* Products and Details */
        error: 'Erreur lors de la recherche de produits: ',
        noProductsAvailable: 'Aucun produit disponible pour le moment.',
        loading: 'Chargement...',
        notFound: 'Produit non trouvé',
        notAvailable: 'Non disponible',
        description: 'Description',
        details: 'Détails du produit',
        price: 'Prix',
        adtImages: 'Images supplémentaires:',
        contactSeller: 'Contacter le vendeur',
        /* Contact */
        contact_us: 'Contactez-nous',
        find: 'Trouvez-nous ici!',
        Chat: 'parler avec nous sur WhatsApp',
        /* footer */
        adAndCellPhone: 'Adresse et téléphone portable',
        KeepInTouch: 'Restez en contact',
        Search: 'Rechercher',
        PrivPolicy: 'Politique de confidentialité',
        RetPolicy: 'Politique de retour',
        TermService: 'Conditions de service',
        PayMeth: 'Méthodes de paiement',
        // Política de Privacidade
        privacy_policy_title: 'Politique de confidentialité',
        privacy_policy_introduction: 'Cette politique de confidentialité décrit comment vos informations personnelles sont collectées, utilisées et partagées lorsque vous visitez ou effectuez un achat sur notre site.',
        privacy_policy_personal_info_title: 'Informations personnelles que nous collectons',
        privacy_policy_personal_info_description: 'Lorsque vous visitez le site, nous collectons automatiquement certaines informations sur votre appareil, notamment des informations sur votre navigateur web, votre adresse IP, votre fuseau horaire, ainsi que certaines des données de cookies installés sur votre appareil. De plus, lorsque vous naviguez sur le site, nous recueillons des informations sur les pages web ou produits individuels que vous consultez, les sites web ou termes de recherche qui vous ont dirigé vers notre site, ainsi que des informations sur la manière dont vous interagissez avec le site. Nous appelons ces informations automatiquement collectées « Informations sur l’Appareil ».',
        privacy_policy_cookies: 'Les « cookies » sont des fichiers de données placés sur votre appareil ou ordinateur qui incluent souvent un identifiant unique anonyme. Pour plus d\'informations sur les cookies et sur la manière de les désactiver, visitez http://www.allaboutcookies.org.',
        privacy_policy_log_files: 'Les « fichiers journaux » suivent les actions effectuées sur le site et collectent des données telles que votre adresse IP, le type de navigateur, le fournisseur d’accès Internet, les pages de référence/sortie et les horodatages.',
        privacy_policy_web_beacons: 'Les « balises web », « tags » et « pixels » sont des fichiers électroniques utilisés pour enregistrer des informations sur la manière dont vous naviguez sur le site.',
        privacy_policy_usage_title: 'Comment utilisons-nous vos informations personnelles ?',
        privacy_policy_usage_description: 'Nous utilisons les Informations sur la Commande que nous collectons généralement pour traiter les commandes passées via le site (y compris le traitement de vos informations de paiement, l’organisation de la livraison et la fourniture de factures et de confirmations de commande).',
        privacy_policy_usage_purposes: [
          'Communiquer avec vous.',
          'Examiner nos commandes pour détecter les risques ou fraudes potentielles.',
          'Vous fournir des informations ou publicités concernant nos produits ou services, conformément à vos préférences.',
        ],
        privacy_policy_sharing_title: 'Partage de vos informations personnelles',
        privacy_policy_sharing_description: 'Nous partageons vos Informations Personnelles avec des tiers pour nous aider à les utiliser comme décrit ci-dessus. Par exemple, nous utilisons Shopify pour alimenter notre boutique en ligne – vous pouvez en savoir plus sur la manière dont Shopify utilise vos Informations Personnelles ici : https://www.shopify.com/legal/privacy.',
        privacy_policy_advertising_title: 'Publicité Comportementale',
        privacy_policy_advertising_description: 'Comme décrit ci-dessus, nous utilisons vos Informations Personnelles pour vous proposer des publicités ciblées ou des communications marketing qui, selon nous, pourraient vous intéresser.',
        privacy_policy_advertising_optout: [
          'Facebook : https://www.facebook.com/settings/?tab=ads',
          'Google : https://www.google.com/settings/ads/anonymous',
          'Bing : https://advertise.bingads.microsoft.com/fr-fr/resources/policies/personalized-ads',
        ],
        privacy_policy_tracking_title: 'Ne Pas Suivre',
        privacy_policy_tracking_description: 'Veuillez noter que nous ne modifions pas les pratiques de collecte et d’utilisation de données de notre site lorsque nous voyons un signal « Ne pas suivre » provenant de votre navigateur.',
        privacy_policy_rights_title: 'Vos droits',
        privacy_policy_rights_description: 'Si vous êtes résident européen, vous avez le droit d’accéder aux informations personnelles que nous détenons à votre sujet et de demander à ce que vos informations personnelles soient corrigées, mises à jour ou supprimées.',
        privacy_policy_data_retention_title: 'Conservation des données',
        privacy_policy_data_retention_description: 'Lorsque vous passez une commande via le site, nous conserverons vos Informations sur la Commande pour nos archives, sauf si et jusqu’à ce que vous nous demandiez de supprimer ces informations.',
        privacy_policy_minors_title: 'Mineurs',
        privacy_policy_minors_description: 'Le site n’est pas destiné aux individus de moins de 18 ans.',
        privacy_policy_changes_title: 'Modifications',
        privacy_policy_changes_description: 'Nous pouvons mettre à jour cette politique de confidentialité de temps en temps afin de refléter, par exemple, des changements dans nos pratiques ou pour d’autres raisons opérationnelles, légales ou réglementaires.',
        privacy_policy_contact_title: 'Nous contacter',
        privacy_policy_contact_description: 'Pour plus d\'informations sur nos pratiques en matière de confidentialité, veuillez nous contacter.',
        // Refund Policy (FR)
        refund_policy_title: "Politique de retour",
        refund_policy_introduction: "Nous avons une politique de retour de 30 jours, ce qui signifie que vous avez 30 jours après avoir reçu votre article pour demander un retour.",
        refund_policy_eligibility_title: "Éligibilité pour les retours",
        refund_policy_eligibility_description: "Pour être éligible à un retour, votre article doit être dans le même état que vous l'avez reçu, non porté ou non utilisé, avec les étiquettes, et dans son emballage d'origine. Vous aurez également besoin du reçu ou d'une preuve d'achat.",
        refund_policy_start_return_title: "Comment initier un retour",
        refund_policy_start_return_description: "Pour initier un retour, rendez-vous simplement sur notre page de contact et expliquez-nous le problème exact afin que nous puissions démarrer le processus de retour/remboursement.",
        refund_policy_contact_questions: "Vous pouvez toujours nous contacter pour toute question concernant les retours.",
        refund_policy_damages_title: "Dommages et problèmes",
        refund_policy_damages_description: "Veuillez inspecter votre commande dès sa réception et nous contacter immédiatement si l'article est défectueux, endommagé, ou si vous recevez le mauvais article, afin que nous puissions évaluer le problème et le corriger.",
        refund_policy_damages_additional: "Si vous recevez des produits endommagés, assurez-vous de nous contacter et de nous envoyer une vidéo dès que possible afin que nous puissions résoudre le problème pour vous.",
        refund_policy_exchanges_title: "Échanges",
        refund_policy_exchanges_description: "La manière la plus rapide d'obtenir ce que vous voulez est de retourner l'article que vous avez, et une fois le retour accepté, effectuer un achat séparé pour le nouvel article.",
        refund_policy_refunds_title: "Remboursements",
        refund_policy_refunds_description: "Nous vous informerons une fois que nous aurons reçu et inspecté votre retour, et nous vous dirons si le remboursement a été approuvé ou non. Si approuvé, vous serez automatiquement remboursé sur votre méthode de paiement d'origine.",
        refund_policy_refunds_additional: "Veuillez noter que votre banque ou votre compagnie de carte de crédit peut prendre un certain temps pour traiter et publier le remboursement.",
        refund_policy_thanks: "Merci.",
        // FAQ (FR)
        faq_title: "Questions Fréquemment Posées",
        faq_question_1: "Puis-je retourner l'article s'il ne me convient pas ?",
        faq_answer_1: "Oui. Si votre produit est endommagé ou ne fonctionne pas comme décrit, contactez-nous dans les 30 jours suivant la réception pour un remboursement à 100 %.",
        faq_question_2: "Comment puis-je vérifier le statut de ma commande ?",
        faq_answer_2: "Vous recevrez votre reçu par email après avoir passé commande. Une fois expédiée, un numéro de suivi vous sera envoyé pour suivre votre colis sur la page Suivre Commande.",
        faq_question_3: "Offrez-vous une garantie de qualité pour tous les produits ?",
        faq_answer_3: "Absolument ! Si vous n'aimez pas le produit ou s'il est endommagé, contactez-nous immédiatement ! Nous garantissons la meilleure expérience d'achat.",
        faq_question_4: "Comment puis-je vous contacter ?",
        faq_answer_4: "Nous gérons le service client par email. Cliquez ici pour ouvrir une discussion avec nous.",
        faq_question_5: "Offrez-vous un remboursement si je n'aime pas le produit ?",
        faq_answer_5: "Absolument ! Nous offrons un remboursement complet si vous n'aimez pas le produit. Contactez-nous pour résoudre cela.",
        faq_question_6: "Comment commander sur votre site ?",
        faq_answer_6: "Sélectionnez le produit et cliquez sur 'Acheter maintenant'. Remplissez vos informations et nous expédierons votre commande rapidement.",
        // Terms of Service (FR)
        tos_title: "Conditions d'utilisation",
        tos_overview: "Ce site Web est exploité par Onord Laptop. Partout sur le site, les termes « nous », « notre » et « nos » font référence à Onord Laptop. Onord Laptop propose ce site Web, y compris toutes les informations, outils et services disponibles sur ce site pour vous, l'utilisateur, sous réserve de votre acceptation de tous les termes, conditions, politiques et avis énoncés ici.",
        tos_section_1_title: "SECTION 1 - CONDITIONS DE LA BOUTIQUE EN LIGNE",
        tos_section_1_content: "En acceptant ces conditions d'utilisation, vous déclarez que vous avez atteint l'âge de la majorité dans votre état ou province de résidence, ou que vous êtes majeur et que vous avez donné votre consentement pour permettre à vos mineurs à charge d'utiliser ce site. Vous ne pouvez pas utiliser nos produits à des fins illégales ou non autorisées ni, dans l'utilisation du Service, enfreindre les lois de votre juridiction (y compris, mais sans s'y limiter, les lois sur les droits d'auteur). Vous ne devez pas transmettre de vers ou de virus ou tout code de nature destructrice. Une violation de ces conditions entraînera une résiliation immédiate de vos Services.",
        tos_section_2_title: "SECTION 2 - CONDITIONS GÉNÉRALES",
        tos_section_2_content: "Nous nous réservons le droit de refuser le service à quiconque, pour quelque raison que ce soit, à tout moment. Vous comprenez que votre contenu (à l'exception des informations de carte de crédit) peut être transféré de manière non cryptée et impliquer des transmissions sur divers réseaux; et des modifications pour se conformer et s'adapter aux exigences techniques des réseaux ou appareils connectés. Les informations de carte de crédit sont toujours cryptées lors de leur transfert sur les réseaux.",
        tos_section_3_title: "SECTION 3 - EXACTITUDE, EXHAUSTIVITÉ ET ACTUALITÉ DES INFORMATIONS",
        tos_section_3_content: "Nous ne sommes pas responsables si les informations mises à disposition sur ce site ne sont pas exactes, complètes ou actuelles. Les informations sur ce site sont fournies à titre indicatif uniquement et ne doivent pas être utilisées comme seule base pour prendre des décisions sans consulter des sources d'informations primaires, plus précises, plus complètes ou plus actuelles. Toute confiance accordée aux informations sur ce site est à vos propres risques.",
        tos_section_4_title: "SECTION 4 - MODIFICATIONS DU SERVICE ET DES PRIX",
        tos_section_4_content: "Les prix de nos produits sont susceptibles de changer sans préavis. Nous nous réservons le droit de modifier ou d'interrompre le Service (ou toute partie ou contenu de celui-ci) à tout moment, sans préavis.",
        tos_section_5_title: "SECTION 5 - PRODUITS OU SERVICES (SI APPLICABLE)",
        tos_section_5_content: "Certains produits ou services peuvent être disponibles exclusivement en ligne via le site Web. Ces produits ou services peuvent avoir des quantités limitées et sont soumis à une politique de retour ou d'échange uniquement conformément à notre Politique de retour.",
        tos_section_6_title: "SECTION 6 - EXACTITUDE DE LA FACTURATION ET DES INFORMATIONS DU COMPTE",
        tos_section_6_content: "Nous nous réservons le droit de refuser toute commande que vous passez chez nous. Nous pouvons, à notre seule discrétion, limiter ou annuler les quantités achetées par personne, par foyer ou par commande.",
        tos_section_7_title: "SECTION 7 - OUTILS OPTIONNELS",
        tos_section_7_content: "Nous pouvons vous fournir l'accès à des outils tiers sur lesquels nous n'exerçons ni contrôle ni influence.",
        tos_section_8_title: "SECTION 8 - LIENS TIERS",
        tos_section_8_content: "Certains contenus, produits et services disponibles via notre Service peuvent inclure des éléments de tiers.",
        tos_section_9_title: "SECTION 9 - COMMENTAIRES DES UTILISATEURS",
        tos_section_9_content: "Si, à notre demande, vous envoyez certaines soumissions spécifiques (par exemple, des participations à des concours) ou sans demande de notre part, vous envoyez des idées créatives, suggestions, propositions, plans ou autres documents, vous acceptez que nous puissions, à tout moment, éditer, copier, publier et utiliser dans tout média les commentaires que vous nous transmettez.",
        tos_section_10_title: "SECTION 10 - INFORMATIONS PERSONNELLES",
        tos_section_10_content: "Votre soumission d'informations personnelles via la boutique est régie par notre Politique de confidentialité.",
        tos_section_11_title: "SECTION 11 - ERREURS, INEXACTITUDES ET OMISSIONS",
        tos_section_11_content: "Il peut arriver qu'il y ait des informations sur notre site ou dans le Service contenant des erreurs typographiques, des inexactitudes ou des omissions concernant les descriptions de produits, les prix, les promotions, les offres, les frais de livraison des produits, les temps de transit et la disponibilité.",
        tos_section_12_title: "SECTION 12 - USAGES INTERDITS",
        tos_section_12_content: "En plus des autres interdictions énoncées dans les Conditions d'utilisation, il vous est interdit d'utiliser le site ou son contenu à des fins illégales ou non autorisées.",
        tos_section_13_title: "SECTION 13 - EXONÉRATION DE GARANTIES; LIMITATION DE RESPONSABILITÉ",
        tos_section_13_content: "Nous ne garantissons pas que votre utilisation de notre service sera ininterrompue, rapide, sécurisée ou sans erreur.",
        tos_section_14_title: "SECTION 14 - INDEMNISATION",
        tos_section_14_content: "Vous acceptez d'indemniser, défendre et dégager de toute responsabilité Onord Laptop et nos filiales, sociétés affiliées, partenaires, dirigeants, agents, sous-traitants, fournisseurs et employés.",
        tos_section_15_title: "SECTION 15 - DIVISIBILITÉ",
        tos_section_15_content: "Si une disposition de ces Conditions d'utilisation est jugée illégale, nulle ou inapplicable, cette disposition sera néanmoins applicable dans toute la mesure permise par la loi.",
        tos_section_16_title: "SECTION 16 - RÉSILIATION",
        tos_section_16_content: "Les obligations et responsabilités des parties engagées avant la date de résiliation survivront à la résiliation de cet accord à toutes fins.",
        tos_section_17_title: "SECTION 17 - INTÉGRALITÉ DE L'ACCORD",
        tos_section_17_content: "Ces Conditions d'utilisation constituent l'intégralité de l'accord entre vous et nous et régissent votre utilisation du Service.",
        tos_section_18_title: "SECTION 18 - LOI APPLICABLE",
        tos_section_18_content: "Ces Conditions d'utilisation et tout accord séparé par lequel nous vous fournissons des Services seront régis par les lois du Canada.",
        tos_section_19_title: "SECTION 19 - MODIFICATIONS DES CONDITIONS D'UTILISATION",
        tos_section_19_content: "Vous pouvez consulter la version la plus récente des Conditions d'utilisation à tout moment sur cette page.",
        tos_section_20_title: "SECTION 20 - INFORMATIONS DE CONTACT",
        tos_section_20_content: "Les questions concernant les Conditions d'utilisation doivent être envoyées à : support@onordlaptop.com.",
    },
  },
  en: {
    translation: {
        welcome: 'Welcome to Onord Laptop Canada!',
        /* Header bar */
        home: 'Home',
        products: 'Products',
        about: 'About Us',
        contact: 'Contact',
        Search: 'Search',
        searchTitle: 'Search for products',
        searchButton: 'Search',
        searchPlaceholder: 'Search for products...',
        /* About us page */
        paragraph1: 'Onord Laptop serves customers worldwide. We strive to keep customers happy with great prices and excellent customer service! Carrying all the latest brands, Onord Laptop is a one-stop shop for all your daily needs! Super fast service guaranteed that all orders are shipped within 48 hours and tracking information is provided for every order! Feel free to contact us with any questions or concerns you may have.',
        paragraph2: 'Our goal is to import quality products and provide great value and shopping experience to our customers. We believe that you will also be 100% satisfied with our products and services. As always, we are very proud to offer the absolute best prices and not compromise on quality.',
        paragraph3: 'We always try to provide customers with an excellent online shopping experience by ensuring not only a smooth purchasing process, but also good after-sales service and used laptops for sale. Feel free to contact us and give us feedback, returns, suggestions or even reviews.',
        paragraph4: 'Life is too short to live in chaos, take it easy and have fun using creative stuff.',
        paragraph5: 'Thank you for visiting - feel free to contact us if you have any questions.',
        paragraph6: 'Email: ',
        paragraph62: 'Opening hours: 9 am to 6 pm (Monday to Friday)',
        paragraph7: 'Happy shopping!',
        careers: 'Careers',
        internship: 'Apply for an internship at Onord',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        message: 'Message',
        submit: 'Submit',
        /* Products and Details */
        error: 'Error while fetching products: ',
        noProductsAvailable: 'No products available at the moment.',
        loading: 'Loading...',
        notFound: 'Product not found',
        notAvailable: 'Not available',
        description: 'Description',
        details: 'Product Details',
        price: 'Price',
        adtImages: 'Additional images:',
        contactSeller: 'Contact Seller',
        /* Contact */
        contact_us: 'Contact Us',
        find: 'Find us here!',
        Chat: 'Chat with us on WhatsApp',
        /* footer */
        adAndCellPhone: 'Address and Cell Phone',
        KeepInTouch: 'Keep in touch',
        Search: 'Search',
        PrivPolicy: 'Privacy Policy',
        RetPolicy: 'Return Policy',
        TermService: 'Terms of Service',
        PayMeth: 'Payment Methods',
        // Privacy Policy
        privacy_policy_title: 'Privacy Policy',
        privacy_policy_introduction: 'This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from our site.',
        privacy_policy_personal_info_title: 'Personal Information We Collect',
        privacy_policy_personal_info_description: 'When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We call this automatically collected information "Device Information".',
        privacy_policy_cookies: 'Cookies are data files that are placed on your device or computer and often include a unique anonymous identifier. For more information about cookies and how to disable cookies, visit http://www.allaboutcookies.org.',
        privacy_policy_log_files: 'Log files track actions occurring on the Site and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.',
        privacy_policy_web_beacons: 'Web beacons, tags, and pixels are electronic files used to record information about how you browse the Site.',
        privacy_policy_usage_title: 'How Do We Use Your Personal Information?',
        privacy_policy_usage_description: 'We use the Order Information that we collect to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and order confirmations).',
        privacy_policy_usage_purposes: [
          'Communicate with you.',
          'Screen our orders for potential risk or fraud.',
          'Provide you with information or advertising relating to our products or services, in line with the preferences you have shared.',
        ],
        privacy_policy_sharing_title: 'Sharing Your Personal Information',
        privacy_policy_sharing_description: 'We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Shopify to power our online store – you can read more about how Shopify uses your Personal Information here: https://www.shopify.com/legal/privacy.',
        privacy_policy_advertising_title: 'Behavioral Advertising',
        privacy_policy_advertising_description: 'As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.',
        privacy_policy_advertising_optout: [
          'Facebook: https://www.facebook.com/settings/?tab=ads',
          'Google: https://www.google.com/settings/ads/anonymous',
          'Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads',
        ],
        privacy_policy_tracking_title: 'Do Not Track',
        privacy_policy_tracking_description: 'Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.',
        privacy_policy_rights_title: 'Your Rights',
        privacy_policy_rights_description: 'If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information is corrected, updated, or deleted.',
        privacy_policy_data_retention_title: 'Data Retention',
        privacy_policy_data_retention_description: 'When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.',
        privacy_policy_minors_title: 'Minors',
        privacy_policy_minors_description: 'The Site is not intended for individuals under the age of 18.',
        privacy_policy_changes_title: 'Changes',
        privacy_policy_changes_description: 'We may update this privacy policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.',
        privacy_policy_contact_title: 'Contact Us',
        privacy_policy_contact_description: 'For more information about our privacy practices, please contact us.',
        // Refund Policy
        refund_policy_title: "Return Policy",
        refund_policy_introduction: "We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.",
        refund_policy_eligibility_title: "Eligibility for Returns",
        refund_policy_eligibility_description: "To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.",
        refund_policy_start_return_title: "How to Start a Return",
        refund_policy_start_return_description: "To start a return, just go to our contact page and tell us the exact issue so we can start the return/refund process.",
        refund_policy_contact_questions: "You can always contact us for any return questions.",
        refund_policy_damages_title: "Damages and Issues",
        refund_policy_damages_description: "Please inspect your order upon reception and contact us immediately if the item is defective, damaged, or if you receive the wrong item, so that we can evaluate the issue and make it right.",
        refund_policy_damages_additional: "If you receive damaged products, make sure to reach out to us and send us video asap. So we can get the issue fixed for you.",
        refund_policy_exchanges_title: "Exchanges",
        refund_policy_exchanges_description: "The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.",
        refund_policy_refunds_title: "Refunds",
        refund_policy_refunds_description: "We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method.",
        refund_policy_refunds_additional: "Please remember it can take some time for your bank or credit card company to process and post the refund too.",
        refund_policy_thanks: "Thank You.",
        // FAQ
        faq_title: "Frequently Asked Questions",
        faq_question_1: "Can I return the item if it doesn't work for me?",
        faq_answer_1: "Yes. If your product is damaged or doesn’t work as described, just reach out to us within 30 days of receiving the product and you will get a 100% Money back.",
        faq_question_2: "How do I check the status of my order?",
        faq_answer_2: "You will be emailed your receipt immediately after you placed your order. Once your order has been shipped, you will receive your tracking number. Use it to track your parcel on our Track Order page.",
        faq_question_3: "Do you provide a quality guarantee for all the products being sold?",
        faq_answer_3: "Absolutely! If you do not like the product or find damages to the product, contact us immediately! We ensure the best shopping experience.",
        faq_question_4: "How can I get in contact with you?",
        faq_answer_4: "We handle customer service through email. Click here to open up a chat with us.",
        faq_question_5: "Do you offer a refund if I don't like the product?",
        faq_answer_5: "Absolutely! We offer a full refund on your order if you don't like the product. Reach out to us to fix it.",
        faq_question_6: "How do I order on your website?",
        faq_answer_6: "Simply select the product and click 'Buy Now'. Fill in your information, and we'll ship your order promptly.",
        // Terms of Service
        // Terms of Service (EN)
        tos_title: "Terms of Service",
        tos_overview: "This website is operated by Onord Laptop. Throughout the site, the terms “we”, “us” and “our” refer to Onord Laptop. Onord Laptop offers this website, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.",
        tos_section_1_title: "SECTION 1 - ONLINE STORE TERMS",
        tos_section_1_content: "By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority and have given your consent to allow any minor dependents to use this site. You may not use our products for any illegal or unauthorized purpose, nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services.",
        tos_section_2_title: "SECTION 2 - GENERAL CONDITIONS",
        tos_section_2_content: "We reserve the right to refuse service to anyone, for any reason, at any time. You understand that your content (excluding credit card information) may be transferred unencrypted and involve transmissions over various networks and changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.",
        tos_section_3_title: "SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION",
        tos_section_3_content: "We are not responsible if the information made available on this site is not accurate, complete, or current. The material on this site is provided for general information only and should not be relied upon as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information. Any reliance on the material on this site is at your own risk.",
        tos_section_4_title: "SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES",
        tos_section_4_content: "Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice.",
        tos_section_5_title: "SECTION 5 - PRODUCTS OR SERVICES (IF APPLICABLE)",
        tos_section_5_content: "Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.",
        tos_section_6_title: "SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION",
        tos_section_6_content: "We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order.",
        tos_section_7_title: "SECTION 7 - OPTIONAL TOOLS",
        tos_section_7_content: "We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.",
        tos_section_8_title: "SECTION 8 - THIRD-PARTY LINKS",
        tos_section_8_content: "Certain content, products, and services available via our Service may include materials from third parties.",
        tos_section_9_title: "SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS",
        tos_section_9_content: "If, at our request, you send certain specific submissions (e.g., contest entries) or, without a request from us, you send creative ideas, suggestions, proposals, plans, or other materials, you agree that we may, at any time, edit, copy, publish, distribute, and otherwise use any comments you forward to us.",
        tos_section_10_title: "SECTION 10 - PERSONAL INFORMATION",
        tos_section_10_content: "Your submission of personal information through the store is governed by our Privacy Policy.",
        tos_section_11_title: "SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS",
        tos_section_11_content: "Occasionally, there may be information on our site or in the Service that contains typographical errors, inaccuracies, or omissions relating to product descriptions, pricing, promotions, offers, shipping charges, transit times, and availability.",
        tos_section_12_title: "SECTION 12 - PROHIBITED USES",
        tos_section_12_content: "In addition to other prohibitions as outlined in the Terms of Service, you are prohibited from using the site or its content for unlawful purposes or unauthorized activities.",
        tos_section_13_title: "SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY",
        tos_section_13_content: "We do not guarantee that your use of our service will be uninterrupted, timely, secure, or error-free.",
        tos_section_14_title: "SECTION 14 - INDEMNIFICATION",
        tos_section_14_content: "You agree to indemnify, defend, and hold harmless Onord Laptop and our affiliates, partners, agents, suppliers, and employees.",
        tos_section_15_title: "SECTION 15 - SEVERABILITY",
        tos_section_15_content: "If any provision of these Terms of Service is found to be unlawful, void, or unenforceable, that provision shall still be enforceable to the fullest extent permitted by law.",
        tos_section_16_title: "SECTION 16 - TERMINATION",
        tos_section_16_content: "The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.",
        tos_section_17_title: "SECTION 17 - ENTIRE AGREEMENT",
        tos_section_17_content: "These Terms of Service constitute the entire agreement and understanding between you and us and govern your use of the Service.",
        tos_section_18_title: "SECTION 18 - GOVERNING LAW",
        tos_section_18_content: "These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of the United States.",
        tos_section_19_title: "SECTION 19 - CHANGES TO TERMS OF SERVICE",
        tos_section_19_content: "You can review the most current version of the Terms of Service at any time on this page.",
        tos_section_20_title: "SECTION 20 - CONTACT INFORMATION",
        tos_section_20_content: "Questions about the Terms of Service should be sent to us at: support@onordlaptop.com.",
    },
  },
  es: {
    translation: {
        welcome: 'Bienvenido à Onord Laptop Canada!',
        /* Header bar */
        home: 'Inicio',
        products: 'Productos',
        about: 'Sobre nosotros',
        contact: 'Contacto',
        Search: 'Buscar',
        searchTitle: 'Buscar productos',
        searchButton: 'Buscar',
        searchPlaceholder: 'Buscar productos...',
        /* About us page */
        paragraph1: 'Onord Laptop sirve a clientes de todo el mundo. ¡Nos esforzamos por mantener a los clientes felices con excelentes precios y un excelente servicio al cliente! ¡Llevando todas las últimas marcas, Onord Laptop es una tienda única para todas sus necesidades diarias! ¡Servicio súper rápido garantizado que todos los pedidos se envían en 48 horas y se proporciona información de seguimiento para cada pedido! ¡No dude en contactarnos con cualquier pregunta o inquietud que pueda tener!',
        paragraph2: 'Nuestro objetivo es importar productos de calidad y proporcionar gran valor y experiencia de compra a nuestros clientes. Creemos que también estará 100% satisfecho con nuestros productos y servicios. Como siempre, estamos muy orgullosos de ofrecer los mejores precios absolutos y no comprometer la calidad.',
        paragraph3: 'Siempre intentamos proporcionar a los clientes una excelente experiencia de compra en línea al garantizar no solo un proceso de compra fluido, sino también un buen servicio postventa y computadoras portátiles usadas en venta. No dude en contactarnos y darnos comentarios, devoluciones, sugerencias o incluso críticas.',
        paragraph4: 'La vida es demasiado corta para vivir en el caos, tómalo con calma y diviértete usando cosas creativas.',
        paragraph5: 'Gracias por visitarnos - no dude en contactarnos si tiene alguna pregunta.',
        paragraph6: 'Correo electrónico: ',
        paragraph62: 'Horario de atención: de 9 a 18 horas (de lunes a viernes)',
        paragraph7: '¡Feliz compra!',
        careers: 'Carreras',
        internship: 'Postúlate para una pasantía en Onord',
        name: 'Nombre',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        message: 'Mensaje',
        submit: 'Enviar',
        /* Products and Details */
        error: 'Error al buscar productos: ',
        noProductsAvailable: 'No hay productos disponibles en este momento.',
        loading: 'Cargando...',
        notFound: 'Producto no encontrado',
        notAvailable: 'No disponible',
        description: 'Descripción',
        details: 'Detalles del producto',
        price: 'Precio',
        adtImages: 'Imágenes adicionales:',
        contactSeller: 'Contactar al vendedor',
        /* Contact */
        contact_us: 'Contáctanos',
        find: '¡Encuéntranos aquí!',
        Chat: 'Chatea con nosotros en WhatsApp',
        /* footer */
        adAndCellPhone: 'Dirección y teléfono móvil',
        KeepInTouch: 'Mantente en contacto',
        Search: 'Buscar',
        PrivPolicy: 'Política de privacidad',
        RetPolicy: 'Política de devolución',
        TermService: 'Términos de servicio',
        PayMeth: 'Métodos de pago',
        // Política de Privacidad
        privacy_policy_title: 'Política de Privacidad',
        privacy_policy_introduction: 'Esta Política de Privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o realiza una compra en nuestro sitio.',
        privacy_policy_personal_info_title: 'Información Personal que Recopilamos',
        privacy_policy_personal_info_description: 'Cuando visita el sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies instaladas en su dispositivo. Además, mientras navega por el sitio, recopilamos información sobre las páginas web o productos individuales que ve, los sitios web o términos de búsqueda que lo refirieron al sitio, y cómo interactúa con el sitio. Llamamos a esta información recopilada automáticamente "Información del Dispositivo".',
        privacy_policy_cookies: 'Las cookies son archivos de datos colocados en su dispositivo o computadora y, a menudo, incluyen un identificador único anónimo. Para obtener más información sobre las cookies y cómo desactivarlas, visite http://www.allaboutcookies.org.',
        privacy_policy_log_files: 'Los archivos de registro rastrean las acciones que ocurren en el sitio y recopilan datos que incluyen su dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia/salida y marcas de fecha/hora.',
        privacy_policy_web_beacons: 'Las balizas web, etiquetas y píxeles son archivos electrónicos utilizados para registrar información sobre cómo navega por el sitio.',
        privacy_policy_usage_title: '¿Cómo Utilizamos su Información Personal?',
        privacy_policy_usage_description: 'Utilizamos la información de pedido que recopilamos para cumplir con cualquier pedido realizado a través del sitio (incluido el procesamiento de su información de pago, la organización del envío y la provisión de facturas y confirmaciones de pedido).',
        privacy_policy_usage_purposes: [
          'Comunicarnos con usted.',
          'Examinar nuestros pedidos en busca de riesgos o fraudes potenciales.',
          'Proporcionarle información o publicidad relacionada con nuestros productos o servicios, de acuerdo con las preferencias que ha compartido.',
        ],
        privacy_policy_sharing_title: 'Compartir su Información Personal',
        privacy_policy_sharing_description: 'Compartimos su información personal con terceros para ayudarnos a usar su información personal, como se describe anteriormente. Por ejemplo, utilizamos Shopify para potenciar nuestra tienda en línea: puede leer más sobre cómo Shopify utiliza su información personal aquí: https://www.shopify.com/legal/privacy.',
        privacy_policy_advertising_title: 'Publicidad Comportamental',
        privacy_policy_advertising_description: 'Como se describió anteriormente, utilizamos su información personal para proporcionarle anuncios dirigidos o comunicaciones de marketing que creemos pueden interesarle.',
        privacy_policy_advertising_optout: [
          'Facebook: https://www.facebook.com/settings/?tab=ads',
          'Google: https://www.google.com/settings/ads/anonymous',
          'Bing: https://advertise.bingads.microsoft.com/es-es/resources/policies/personalized-ads',
        ],
        privacy_policy_tracking_title: 'No Rastrear',
        privacy_policy_tracking_description: 'Tenga en cuenta que no alteramos nuestras prácticas de recopilación y uso de datos del sitio cuando vemos una señal de No Rastrear desde su navegador.',
        privacy_policy_rights_title: 'Sus Derechos',
        privacy_policy_rights_description: 'Si usted es residente europeo, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que su información personal sea corregida, actualizada o eliminada.',
        privacy_policy_data_retention_title: 'Retención de Datos',
        privacy_policy_data_retention_description: 'Cuando realiza un pedido a través del sitio, mantendremos su información de pedido para nuestros registros a menos que y hasta que nos solicite eliminar esta información.',
        privacy_policy_minors_title: 'Menores',
        privacy_policy_minors_description: 'El sitio no está destinado a individuos menores de 18 años.',
        privacy_policy_changes_title: 'Cambios',
        privacy_policy_changes_description: 'Podemos actualizar esta Política de Privacidad de vez en cuando para reflejar cambios en nuestras prácticas o por otras razones operativas, legales o reglamentarias.',
        privacy_policy_contact_title: 'Contáctenos',
        privacy_policy_contact_description: 'Para obtener más información sobre nuestras prácticas de privacidad, por favor contáctenos.',
        // Refund Policy (ES)
        refund_policy_title: "Política de devoluciones",
        refund_policy_introduction: "Tenemos una política de devolución de 30 días, lo que significa que tienes 30 días después de recibir tu artículo para solicitar una devolución.",
        refund_policy_eligibility_title: "Elegibilidad para devoluciones",
        refund_policy_eligibility_description: "Para ser elegible para una devolución, tu artículo debe estar en las mismas condiciones en que lo recibiste, sin usar, con etiquetas y en su embalaje original. También necesitarás el recibo o comprobante de compra.",
        refund_policy_start_return_title: "Cómo iniciar una devolución",
        refund_policy_start_return_description: "Para iniciar una devolución, simplemente visita nuestra página de contacto y dinos el problema exacto para que podamos comenzar el proceso de devolución/reembolso.",
        refund_policy_contact_questions: "Siempre puedes contactarnos para cualquier pregunta relacionada con devoluciones.",
        refund_policy_damages_title: "Daños y problemas",
        refund_policy_damages_description: "Por favor, inspecciona tu pedido al recibirlo y contáctanos de inmediato si el artículo está defectuoso, dañado o si recibiste el artículo equivocado, para que podamos evaluar el problema y solucionarlo.",
        refund_policy_damages_additional: "Si recibes productos dañados, asegúrate de comunicarte con nosotros y enviarnos un video lo antes posible para que podamos solucionar el problema.",
        refund_policy_exchanges_title: "Intercambios",
        refund_policy_exchanges_description: "La forma más rápida de asegurarte de obtener lo que quieres es devolver el artículo que tienes, y una vez que se acepte la devolución, realizar una compra por separado para el nuevo artículo.",
        refund_policy_refunds_title: "Reembolsos",
        refund_policy_refunds_description: "Te notificaremos una vez que hayamos recibido e inspeccionado tu devolución, y te informaremos si el reembolso fue aprobado o no. Si se aprueba, recibirás automáticamente el reembolso en tu método de pago original.",
        refund_policy_refunds_additional: "Recuerda que tu banco o compañía de tarjeta de crédito puede tardar un tiempo en procesar y publicar el reembolso.",
        refund_policy_thanks: "Gracias.",
        // FAQ (ES)
        faq_title: "Preguntas Frecuentes",
        faq_question_1: "¿Puedo devolver el producto si no me funciona?",
        faq_answer_1: "Sí. Si su producto está dañado o no funciona como se describe, contáctenos dentro de los 30 días posteriores a la recepción para un reembolso del 100 %.",
        faq_question_2: "¿Cómo verifico el estado de mi pedido?",
        faq_answer_2: "Recibirá su recibo por correo electrónico inmediatamente después de realizar su pedido. Una vez enviado, recibirá un número de seguimiento para rastrear su paquete en nuestra página de Seguimiento de Pedidos.",
        faq_question_3: "¿Ofrecen garantía de calidad para todos los productos?",
        faq_answer_3: "¡Absolutamente! Si no le gusta el producto o encuentra daños, ¡contáctenos de inmediato! Garantizamos la mejor experiencia de compra.",
        faq_question_4: "¿Cómo puedo contactarlos?",
        faq_answer_4: "Atendemos el servicio al cliente por correo electrónico. Haga clic aquí para iniciar un chat con nosotros.",
        faq_question_5: "¿Ofrecen reembolso si no me gusta el producto?",
        faq_answer_5: "¡Absolutamente! Ofrecemos un reembolso completo si no le gusta el producto. Contáctenos para solucionarlo.",
        faq_question_6: "¿Cómo hago un pedido en su sitio web?",
        faq_answer_6: "Seleccione el producto y haga clic en 'Comprar ahora'. Complete su información y enviaremos su pedido rápidamente.",
        // TERMS OF SERVICE
        tos_title: "Términos de Servicio",
        tos_overview: "Este sitio web es operado por Onord Laptop. En todo el sitio, los términos “nosotros”, “nuestro” y “nos” se refieren a Onord Laptop. Onord Laptop ofrece este sitio web, incluida toda la información, herramientas y servicios disponibles desde este sitio para usted, el usuario, condicionado a su aceptación de todos los términos, condiciones, políticas y avisos aquí establecidos.",
        tos_section_1_title: "SECCIÓN 1 - TÉRMINOS DE LA TIENDA EN LÍNEA",
        tos_section_1_content: "Al aceptar estos Términos de Servicio, usted declara que tiene al menos la mayoría de edad en su estado o provincia de residencia, o que tiene la mayoría de edad y ha dado su consentimiento para permitir que sus dependientes menores usen este sitio. No puede usar nuestros productos para ningún propósito ilegal o no autorizado, ni, al usar el Servicio, violar ninguna ley en su jurisdicción (incluidas, entre otras, las leyes de derechos de autor). No debe transmitir ningún gusano, virus o código de naturaleza destructiva. Una violación de cualquiera de los Términos resultará en la terminación inmediata de sus Servicios.",
        tos_section_2_title: "SECCIÓN 2 - CONDICIONES GENERALES",
        tos_section_2_content: "Nos reservamos el derecho de rechazar el servicio a cualquier persona, por cualquier motivo, en cualquier momento. Usted comprende que su contenido (excluyendo la información de la tarjeta de crédito) puede transferirse sin cifrar e involucrar transmisiones a través de diversas redes y cambios para adaptarse a los requisitos técnicos de redes o dispositivos conectados. La información de la tarjeta de crédito siempre está cifrada durante la transferencia a través de las redes.",
        tos_section_3_title: "SECCIÓN 3 - EXACTITUD, INTEGRIDAD Y ACTUALIDAD DE LA INFORMACIÓN",
        tos_section_3_content: "No somos responsables si la información disponible en este sitio no es precisa, completa o actual. El material en este sitio se proporciona únicamente para información general y no debe confiarse como la única base para tomar decisiones sin consultar fuentes de información primarias, más precisas, más completas o más oportunas. Cualquier confianza en el material de este sitio es bajo su propio riesgo.",
        tos_section_4_title: "SECCIÓN 4 - MODIFICACIONES AL SERVICIO Y PRECIOS",
        tos_section_4_content: "Los precios de nuestros productos están sujetos a cambios sin previo aviso. Nos reservamos el derecho en cualquier momento de modificar o descontinuar el Servicio (o cualquier parte o contenido del mismo) sin previo aviso.",
        tos_section_5_title: "SECCIÓN 5 - PRODUCTOS O SERVICIOS (SI CORRESPONDE)",
        tos_section_5_content: "Ciertos productos o servicios pueden estar disponibles exclusivamente en línea a través del sitio web. Estos productos o servicios pueden tener cantidades limitadas y están sujetos a devolución o cambio únicamente de acuerdo con nuestra Política de devoluciones.",
        tos_section_6_title: "SECCIÓN 6 - EXACTITUD DE LA FACTURACIÓN E INFORMACIÓN DE LA CUENTA",
        tos_section_6_content: "Nos reservamos el derecho de rechazar cualquier pedido que realice con nosotros. Podemos, a nuestra sola discreción, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido.",
        tos_section_7_title: "SECCIÓN 7 - HERRAMIENTAS OPCIONALES",
        tos_section_7_content: "Podemos proporcionarle acceso a herramientas de terceros sobre las cuales no monitoreamos ni tenemos control ni entrada.",
        tos_section_8_title: "SECCIÓN 8 - ENLACES DE TERCEROS",
        tos_section_8_content: "Cierto contenido, productos y servicios disponibles a través de nuestro Servicio pueden incluir materiales de terceros.",
        tos_section_9_title: "SECCIÓN 9 - COMENTARIOS, RETROALIMENTACIÓN Y OTRAS PRESENTACIONES DE USUARIOS",
        tos_section_9_content: "Si, a nuestra solicitud, envía ciertas presentaciones específicas (por ejemplo, participaciones en concursos) o, sin una solicitud de nuestra parte, envía ideas creativas, sugerencias, propuestas, planes u otros materiales, acepta que podamos, en cualquier momento, editar, copiar, publicar, distribuir y usar de cualquier otra manera los comentarios que nos envíe.",
        tos_section_10_title: "SECCIÓN 10 - INFORMACIÓN PERSONAL",
        tos_section_10_content: "Su envío de información personal a través de la tienda se rige por nuestra Política de privacidad.",
        tos_section_11_title: "SECCIÓN 11 - ERRORES, INEXACTITUDES Y OMISIONES",
        tos_section_11_content: "Ocasionalmente puede haber información en nuestro sitio o en el Servicio que contenga errores tipográficos, inexactitudes u omisiones relacionadas con las descripciones de productos, precios, promociones, ofertas, cargos de envío de productos, tiempos de tránsito y disponibilidad.",
        tos_section_12_title: "SECCIÓN 12 - USOS PROHIBIDOS",
        tos_section_12_content: "Además de otras prohibiciones establecidas en los Términos de Servicio, se le prohíbe usar el sitio o su contenido para fines ilegales o actividades no autorizadas.",
        tos_section_13_title: "SECCIÓN 13 - DESCARGO DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD",
        tos_section_13_content: "No garantizamos que su uso de nuestro servicio será ininterrumpido, oportuno, seguro o libre de errores.",
        tos_section_14_title: "SECCIÓN 14 - INDEMNIZACIÓN",
        tos_section_14_content: "Usted acepta indemnizar, defender y eximir de responsabilidad a Onord Laptop y a nuestros afiliados, socios, agentes, proveedores y empleados.",
        tos_section_15_title: "SECCIÓN 15 - DIVISIBILIDAD",
        tos_section_15_content: "Si alguna disposición de estos Términos de Servicio se encuentra ilegal, nula o inaplicable, dicha disposición seguirá siendo aplicable en la máxima medida permitida por la ley.",
        tos_section_16_title: "SECCIÓN 16 - TERMINACIÓN",
        tos_section_16_content: "Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de terminación sobrevivirán a la terminación de este acuerdo para todos los propósitos.",
        tos_section_17_title: "SECCIÓN 17 - ACUERDO COMPLETO",
        tos_section_17_content: "Estos Términos de Servicio constituyen el acuerdo completo y el entendimiento entre usted y nosotros, y rigen su uso del Servicio.",
        tos_section_18_title: "SECCIÓN 18 - LEY APLICABLE",
        tos_section_18_content: "Estos Términos de Servicio y cualquier acuerdo separado por el cual le proporcionemos Servicios se regirán e interpretarán de acuerdo con las leyes de los Estados Unidos.",
        tos_section_19_title: "SECCIÓN 19 - CAMBIOS A LOS TÉRMINOS DE SERVICIO",
        tos_section_19_content: "Puede revisar la versión más reciente de los Términos de Servicio en cualquier momento en esta página.",
        tos_section_20_title: "SECCIÓN 20 - INFORMACIÓN DE CONTACTO",
        tos_section_20_content: "Las preguntas sobre los Términos de Servicio deben enviarse a: support@onordlaptop.com.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
