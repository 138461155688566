import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

// Products component
const Products = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null); // Selected media for modal
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const videoRefs = useRef([]); // Array to store video references

  // Function to fetch the products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Fetching products from Firestore...");
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Products fetched:", productsList); // Log of products fetched
        setProducts(productsList);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Function to handle the view details button
  const handleViewDetails = (productId) => {
    navigate(`/product/${productId}`);
  };

  // Function to handle media click (stop miniatures, show full screen)
  const handleMediaClick = (media, index) => {
    // Pause all miniature videos
    videoRefs.current.forEach((video, i) => {
      if (video && !video.paused && i !== index) {
        video.pause();
      }
    });

    setSelectedMedia(media);
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    if (selectedMedia?.type === "video") {
      const modalVideo = videoRefs.current.find((ref) => ref?.src === selectedMedia.url);
      if (modalVideo) {
        modalVideo.pause();
      }
    }

    setSelectedMedia(null);
    setShowModal(false);
  };

  // Content of the products page
  return (
    <div className="products-container container mt-4">
      <h2>{t("products")}</h2>
      <div className="products-grid">
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.id} className="product-card">
              <h3>{product.name}</h3>
              <Carousel>
                {product.media && product.media.length > 0 ? (
                  product.media.map((file, index) => (
                    <Carousel.Item key={index}>
                      {file.type === "video" ? (
                        <video
                          ref={(el) => (videoRefs.current[index] = el)} // Attach ref to video
                          className="d-block w-100"
                          controls
                          muted // Ensure videos are muted by default
                          src={file.url}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleMediaClick(file, index)}
                        />
                      ) : (
                        <img
                          className="d-block w-100"
                          src={file.url}
                          alt={`Slide ${index + 1}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleMediaClick(file, index)}
                        />
                      )}
                    </Carousel.Item>
                  ))
                ) : (
                  <p>{t("no Media Available")}</p>
                )}
              </Carousel>
              <br />
              <p>
                {t("price")}: ${product.price}
              </p>
              <button
                onClick={() => handleViewDetails(product.id)}
                className="btn btn-dark"
              >
                {t("details")}
              </button>
            </div>
          ))
        ) : (
          <p>{t("noProductsAvailable")}</p>
        )}
      </div>
      {/* Modal to show the media in full screen */}
      {showModal && selectedMedia && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          onClick={closeModal}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body text-center">
                {selectedMedia.type === "video" ? (
                  <video
                    controls
                    autoPlay
                    muted // Ensure video in modal is muted by default
                    src={selectedMedia.url}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <img
                    src={selectedMedia.url}
                    alt="Full screen image"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
