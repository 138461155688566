import React from "react";
import { useTranslation } from "react-i18next";

const RefundPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="container mt-5">
      <h2 className="text-center">{t("refund_policy_title")}</h2>
      <p>{t("refund_policy_introduction")}</p>

      <h3>{t("refund_policy_eligibility_title")}</h3>
      <p>{t("refund_policy_eligibility_description")}</p>

      <h3>{t("refund_policy_start_return_title")}</h3>
      <p>{t("refund_policy_start_return_description")}</p>
      <p>{t("refund_policy_contact_questions")}</p>

      <h3>{t("refund_policy_damages_title")}</h3>
      <p>{t("refund_policy_damages_description")}</p>
      <p>{t("refund_policy_damages_additional")}</p>

      <h3>{t("refund_policy_exchanges_title")}</h3>
      <p>{t("refund_policy_exchanges_description")}</p>

      <h3>{t("refund_policy_refunds_title")}</h3>
      <p>{t("refund_policy_refunds_description")}</p>
      <p>{t("refund_policy_refunds_additional")}</p>

      <p>{t("refund_policy_thanks")}</p>
    </div>
  );
};

export default RefundPolicy;
