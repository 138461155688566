import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import { auth } from './Pages/firebase';
import Header from './Pages/header';
import Login from './Pages/login';
import Admin from './Pages/admin';
import About from './Pages/about';
import Products from './Pages/products';
import SearchPage from './Pages/searchPage';
import PrivacyPolicy from './Pages/privPol';
import RefundPolicy from './Pages/retPol';
import Faq from './Pages/faq';
import Services from './Pages/services';
import ProductDetails from './Pages/productsDetails';
import Contact from './Pages/contact';
import Footer from './Pages/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';

function App() {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>Loading user authentication...</div>;
  }

  if (error) {
    console.error("Authentication error:", error);
    return <div>Error loading authentication.</div>;
  }

  // Content of the app
  return (
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Products />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<RefundPolicy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={user ? <Admin /> : <Login />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;