import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="container mt-5">
      <h2 className="text-center">{t("privacy_policy_title")}</h2>
      <p>{t("privacy_policy_introduction")}</p>

      <h3>{t("privacy_policy_personal_info_title")}</h3>
      <p>{t("privacy_policy_personal_info_description")}</p>
      <ul>
        <li>{t("privacy_policy_cookies")}</li>
        <li>{t("privacy_policy_log_files")}</li>
        <li>{t("privacy_policy_web_beacons")}</li>
      </ul>

      <h3>{t("privacy_policy_usage_title")}</h3>
      <p>{t("privacy_policy_usage_description")}</p>
      <ul>
        {t("privacy_policy_usage_purposes", { returnObjects: true }).map(
          (purpose, index) => (
            <li key={index}>{purpose}</li>
          )
        )}
      </ul>

      <h3>{t("privacy_policy_sharing_title")}</h3>
      <p>{t("privacy_policy_sharing_description")}</p>

      <h3>{t("privacy_policy_advertising_title")}</h3>
      <p>{t("privacy_policy_advertising_description")}</p>
      <ul>
        {t("privacy_policy_advertising_optout", { returnObjects: true }).map(
          (link, index) => (
            <li key={index}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            </li>
          )
        )}
      </ul>

      <h3>{t("privacy_policy_tracking_title")}</h3>
      <p>{t("privacy_policy_tracking_description")}</p>

      <h3>{t("privacy_policy_rights_title")}</h3>
      <p>{t("privacy_policy_rights_description")}</p>

      <h3>{t("privacy_policy_data_retention_title")}</h3>
      <p>{t("privacy_policy_data_retention_description")}</p>

      <h3>{t("privacy_policy_minors_title")}</h3>
      <p>{t("privacy_policy_minors_description")}</p>

      <h3>{t("privacy_policy_changes_title")}</h3>
      <p>{t("privacy_policy_changes_description")}</p>

      <h3>{t("privacy_policy_contact_title")}</h3>
      <p>{t("privacy_policy_contact_description")}</p>
    </div>
  );
};

export default PrivacyPolicy;
