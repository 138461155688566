import React, { useState, useEffect } from "react";
import { collection, query as firebaseQuery, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

// Number of items per page
const ITEMS_PER_PAGE = 10; // Number of items per page
// SearchPage component
const SearchPage = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  // Capture query parameter from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query") || "";
    setSearchQuery(query);

    if (query) {
      handleSearch(query); // Perform search when query is available
    }
  }, [location.search]);

  // Search function
  const handleSearch = async (searchTerm) => {
    if (searchTerm.trim() === "") {
      setResults([]);
      return;
    }

    setLoading(true);
    try {
      const productsRef = collection(db, "products");
      const searchQuery = firebaseQuery(
        productsRef,
        where("keywords", "array-contains", searchTerm.toLowerCase())
      );
      const querySnapshot = await getDocs(searchQuery);

      const fetchedResults = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setResults(fetchedResults);
      setCurrentPage(1); // Restart pagination
      setTotalPages(Math.ceil(fetchedResults.length / ITEMS_PER_PAGE));
    } catch (error) {
      console.error("Error searching:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Calculate current items to display
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = results.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  
  // Content of the search page
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">{t("searchTitle")}</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch(searchQuery);
        }}
        className="d-flex mb-4"
      >
        <input
          type="text"
          className="form-control me-2"
          placeholder={t("searchPlaceholder")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button type="submit" className="btn btn-primary">
          {t("searchButton")}
        </button>
      </form>

      {loading && <p className="text-center">{t("loading")}</p>}

      {results.length > 0 ? (
        <>
          <div className="results-list">
            {currentItems.map((item) => (
              <div
                key={item.id}
                className="d-flex align-items-center justify-content-between mb-3"
                style={{ borderBottom: "1px solid #ccc", paddingBottom: "10px" }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={item.images ? item.images[0] : "placeholder.jpg"}
                    alt={item.name}
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      marginRight: "15px",
                    }}
                    onError={(e) => (e.target.src = "placeholder.jpg")}
                  />
                  <div>
                    <h5>{item.name}</h5>
                    <p>{item.description.en}</p>
                  </div>
                </div>
                <div>
                  <Link
                    to={`/product/${item.id}`}
                    className="btn btn-primary"
                    aria-label={`View details of ${item.name}`}
                  >
                    {t("viewDetails")}
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className="pagination d-flex justify-content-center align-items-center mt-4">
            <button
              className={`btn btn-outline-primary me-2 ${currentPage === 1 ? "disabled" : ""}`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              ←
            </button>
            <span>
              {t("page")} {currentPage} {t("of")} {totalPages}
            </span>
            <button
              className={`btn btn-outline-primary ms-2 ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              →
            </button>
          </div>
        </>
      ) : !loading && <p className="text-center mt-5">{t("notFound")}</p>}
    </div>
  );
};

export default SearchPage;