import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

// ProductDetails component
const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMedia, setSelectedMedia] = useState(null); // state to store the selected media
  const [showModal, setShowModal] = useState(false); // state to control the modal visibility
  const videoRefs = useRef([]); // array to store the video references
  const { t, i18n } = useTranslation();

  // Function to fetch the product from Firestore
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, "products", productId);
        const productSnapshot = await getDoc(productRef);
        if (productSnapshot.exists()) {
          setProduct({ id: productSnapshot.id, ...productSnapshot.data() });
        } else {
          console.error(t("notFound"));
        }
      } catch (error) {
        console.error(t("error"), error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId, t]);

  // Function to get the translated text
  const getTranslatedText = (textObj) => {
    if (!textObj || typeof textObj !== "object") return textObj || t("notAvailable");
    return textObj[i18n.language] || textObj["en"] || t("notAvailable");
  };

  // Function to handle media click (stop miniatures, show full screen)
  const handleMediaClick = (media, index) => {
    videoRefs.current.forEach((video, i) => {
      if (video && !video.paused && i !== index) {
        video.pause();
      }
    });

    setSelectedMedia(media);
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    if (selectedMedia?.type === "video") {
      const modalVideo = videoRefs.current.find((ref) => ref?.src === selectedMedia.url);
      if (modalVideo) {
        modalVideo.pause();
      }
    }

    setSelectedMedia(null);
    setShowModal(false);
  };

  // Function to handle the contact seller button
  const handleContactSeller = () => {
    if (!product) return;
    const code = product.code || t("notAvailable");
    const whatsappLink = `https://wa.me/14386808564?text=${encodeURIComponent(
      `Hello, I am interested in this laptop: ${getTranslatedText(product.name)} Code: ${code}`
    )}`;
    window.open(whatsappLink, "_blank");
  };

  if (loading) {
    return (
      <div className="container mt-5">
        <p>{t("loading")}</p>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="container mt-5">
        <p>{t("notFound")}</p>
      </div>
    );
  }

  // Content of the product details page
  return (
    <div className="container mt-5">
      <h2 className="mb-4">{getTranslatedText(product.name)}</h2>
      {product.media && product.media.length > 0 && (
        <div className="mb-3">
          {product.media[0].type === "video" ? (
            <video
              ref={(el) => (videoRefs.current[0] = el)} // Attach ref to video
              src={product.media[0].url}
              controls
              muted
              className="img-fluid"
              style={{ maxHeight: "300px", cursor: "pointer" }}
              onClick={() => handleMediaClick(product.media[0], 0)}
            />
          ) : (
            <img
              src={product.media[0].url}
              alt={`Imagem de ${getTranslatedText(product.name)}`}
              className="img-fluid"
              style={{ maxHeight: "300px", cursor: "pointer" }}
              onClick={() => handleMediaClick(product.media[0], 0)}
            />
          )}
        </div>
      )}
      <p>
        <strong>Code: {getTranslatedText(product.code)}</strong>
      </p>
      <p>
        <strong>{t("description")}:</strong> {getTranslatedText(product.description)}
      </p>
      <p>
        <strong>{t("price")}: </strong>
        {product.price ? `CAD$ ${product.price.toFixed(2)}` : t("notAvailable")}
      </p>
      {product.media && product.media.length > 1 && (
        <div>
          <h4>{t("adtImages")}</h4>
          <div className="d-flex flex-wrap">
            {product.media.slice(1).map((file, index) => (
              <div key={index} className="m-2">
                {file.type === "video" ? (
                  <video
                    ref={(el) => (videoRefs.current[index + 1] = el)} // Attach ref to video
                    src={file.url}
                    controls
                    muted
                    className="img-thumbnail"
                    style={{ maxWidth: "150px", height: "auto", cursor: "pointer" }}
                    onClick={() => handleMediaClick(file, index + 1)}
                  />
                ) : (
                  <img
                    src={file.url}
                    alt={`Imagem adicional ${index + 1}`}
                    className="img-thumbnail"
                    style={{ maxWidth: "150px", height: "auto", cursor: "pointer" }}
                    onClick={() => handleMediaClick(file)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* Button to contact the seller */}
      <div className="mt-4">
        <button className="btn btn-primary" onClick={handleContactSeller}>
          {t("contactSeller")}
        </button>
      </div>

      {/* Modal to show the image or video in full screen */}
      {showModal && selectedMedia && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          onClick={closeModal}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body text-center">
                {selectedMedia.type === "video" ? (
                  <video
                    controls
                    autoPlay
                    muted
                    src={selectedMedia.url}
                    className="w-100"
                  />
                ) : (
                  <img
                    src={selectedMedia.url}
                    alt="Full screen image"
                    className="w-100"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
