import React from "react";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <div className="container mt-5">
      <h2 className="text-center">{t("faq_title")}</h2>
      <div className="faq-item">
        <h3>{t("faq_question_1")}</h3>
        <p>{t("faq_answer_1")}</p>
      </div>
      <div className="faq-item">
        <h3>{t("faq_question_2")}</h3>
        <p>{t("faq_answer_2")}</p>
      </div>
      <div className="faq-item">
        <h3>{t("faq_question_3")}</h3>
        <p>{t("faq_answer_3")}</p>
      </div>
      <div className="faq-item">
        <h3>{t("faq_question_4")}</h3>
        <p>{t("faq_answer_4")}</p>
      </div>
      <div className="faq-item">
        <h3>{t("faq_question_5")}</h3>
        <p>{t("faq_answer_5")}</p>
      </div>
      <div className="faq-item">
        <h3>{t("faq_question_6")}</h3>
        <p>{t("faq_answer_6")}</p>
      </div>
    </div>
  );
};

export default Faq;
